.tonton {
    min-height: inherit;
    min-width: inherit;
    background: url('https://headend-api.tonton.com.my/v100/imageHelper.php?id=0:377:STATIC:MARKETING_LANDSCAPE&w=1622&appID=TONTON');
    background-size: cover;
}

.tontonbg {
    padding: 120px 0 100px;
    font-family: 'Helvetica Neue', sans-serif;
    position: relative;
    text-align: left;

}

.tontonbg h1 {
    color: #fff;
}

.tontonbg h4 {
    color: #fff;
}

.tontonbg h6 {
    color: #fff;
    font-weight: normal;
    
}

.tontonbg h6 a {
    color: #ff6a00;
    font-weight: bolder;
    text-decoration: none;
    font-size: 18px;
}
.tontonbg h6 a:hover {
    color: #ff8c3a;
    font-weight: bolder;
    text-decoration: none;
}

.tontonbg h5 {
    color: #fff;
}

.tontonbg .form-control {
    background: #084c688a;
    padding: 15px 20px;
    color: #fff;
    border: 1px solid #fff;
}

.twrap {
    padding: 30px 10px;
    background: linear-gradient(86deg, #0b2038, transparent);

}

.form-control::placeholder {
    color: #fff;
    opacity: 0.5;
}

.btn-tonton {
    background-color: rgb(0, 123, 201);
    padding: 10px 20px;
    color: #fff;
    font-weight: bold;
}
.btn-tonton:focus,
.btn-tonton:hover {
    background: #005e8a;
    text-decoration: none;
    color: #fff;
}

.dZDpRR {
    text-align: center;
    color: rgb(255, 255, 255);
    margin-top: 40px;
    font-size: 13px;
}

.dZDpRR .rev-id {
    margin: 10px auto;
    background: url("https://storage.googleapis.com/static-oauth.revmedia.my/revid.png") center center no-repeat;
    height: 52px;
    width: 138px;
}

.copyright-tonton {
    padding-bottom: 50px;
}

.msg-error {
    color: #fff;
}

.modal {
    background: rgba(0, 0, 0, 0.6);
    padding: 0 1rem;
}

#tontonModal .modal-content {
    max-width: 400px;
    height: max-content;
    background: #062034;
    border-radius: 15px;
    margin: auto;
}

#tontonModal .modal-header {
    border-bottom: none;
    padding: 1.15rem 1rem;
}

#tontonModal .modal-body {
    padding: 50px 30px;
}

#tontonModal .modal-body p {
    font-family: Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

#tontonModal .modal-header .close {
    padding: 0 3px 2px;
}

#tontonModal button.close {
    position: absolute;
    top: 0;
    right: 0;
    color: #00A0DF;
    border-radius: 50%;
    border: 2px solid #00A0DF;
}

#tontonModal .close {
    font-size: 0.9rem;
    text-shadow: none;
    opacity: 1;
}
#tontonModal .close:hover {
    color: #00A0DF;
}

#tontonModal .tonton-logo {
    max-width: 178px;
}

#tontonModal a.open-tonton-btn {
    width: 100%;
    max-width: 286px;
    background: #00A0DF;
    border-radius: 10px;
    padding: 11px;
    margin: auto;
    font-family: Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    color: #FFFFFF;
    text-align: center;
    transition: 0.3s ease-in-out;
}
#tontonModal a.open-tonton-btn:hover {
    text-decoration: none;
    background: #005e8a;
}

.invalid-feedback {
    background: #fff;
    padding: 10px;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none;

}
.form-control.is-valid,
.was-validated .form-control:valid {
    background-image: none;
    border-color: #fff;
}

.menu-bar-container {
    position: fixed;
    width: 100%;
    z-index: 105;
    background: rgba(0, 54, 99, .4);
    height: 70px;
    transition: all 0.3s linear;
}
.menu-bar-container.menu-bar-hidden:not(.hamburgerOpen) {
    transform: translateY(-74px);
}

.menu-bar-container.ua-barrier-menu {
    position: initial;
}

.menu-bar-container.searchBarOpen .image-link {
    display: none;
}

.login .menu-bar-simple {
    box-shadow: 0 0 30px rgba(0, 0, 0, .15);
    background: #fff;
}

.menu-bar {

    min-width: 300px;
    padding: 0 5%;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #061016;
    display: flex;
    justify-content: space-between;
    height: 70px;
    position: static;
    opacity: 0;
    transition: opacity 0.3s;
}

.menu-bar .hidden-hamburger {
    display: none !important;
}

.menu-bar .logo {
    cursor: pointer;
}

.menu-bar .menu-item {
    font-family: "appFontBold", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
}

.menu-bar.show {
    opacity: 1;
}
@media (min-width: 1920px) {
    .menu-bar {
        padding: 0 80px;
    }
}

.menu-bar .logo {
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 25px;
}

.menu-bar .logo img,
.menu-bar .logo svg {
    height: 100%;
    width: auto;
    object-fit: contain;
}

.menu-bar .logo-when-no-menu {
    margin-top: 13px;
    cursor: pointer;
    height: 40px;
}

.menu-bar .logo-when-no-menu img,
.menu-bar .logo-when-no-menu svg {
    height: 100%;
    object-fit: contain;
}

.menu-bar.hamburger .logo-when-no-menu {
    margin-top: 0;
    margin-left: 15px;
}

.menu-bar.standard .menu-item {
    display: inline-block;
    color: #7397a9;
    cursor: pointer;
    padding: 0;
    margin: 0 25px;
    position: relative;
    height: 100%;
    line-height: 69px;
    vertical-align: middle;
    transition: color 0.3s ease;
}

.menu-bar.standard .menu-item a {
    color: #7397a9;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
}
.menu-bar.standard .menu-item a:hover {
    color: #fff;
}

.menu-bar .left-section {
    display: inline-block;
}

.menu-bar.standard .left-section {
    overflow: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.menu-bar.standard .left-section::-webkit-scrollbar {
    display: none;
}
.menu-bar .menu-item.focused,
.menu-bar .menu-item:focus,
.menu-bar .menu-item:hover {
    color: #fff;
}

.menu-bar.standard .menu-item.active {
    color: #fff;
}
.menu-bar .menu-item.active:hover {
    color: #fff;
}

.menu-bar .right-section {
    align-self: flex-end;
    display: flex;
    height: 100%;
    box-sizing: border-box;
}

.menu-bar .right-section .settings-icon {
    vertical-align: middle;
    cursor: pointer;
    color: #7397a9;
    transition: color 0.3s ease;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.menu-item-lo {
    display: flex;
    box-sizing: border-box;
    align-items: center;
}

.menu-item-lo h5 {
    color: #fbfeff;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 0;
}
@media (max-width: 680px) {
    .menu-bar .logo {
        height: 25px;
    }

    .btn-tonton {
        padding: 10px;
        color: #fff;
    }
}
